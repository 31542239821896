@media all and (min-width: 640px) {
    .taskList {
        display: inline-table !important;
    }

    .taskList thead tr:not(:first-child) {
        display: none;
    }

}

html *{
  @apply font-sans text-io-main;
}

.taskListCategory {
    box-shadow: inset 4px 0 0 0;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.navigation-item {
  @apply rounded-lg bg-gray-100 text-gray-600 flex items-center px-2 py-2 font-medium;

  /* Animation so that the hover state has a fade effect (with 150ms) */
  @apply transition duration-150;
}

/* The navigation item for the active navigation element (highlighted in blue) */
.navigation-item.active {
  @apply bg-blue-200 text-blue-800;
}

/* Hover state for the navigation item. */
.navigation-item:hover {
  @apply bg-gray-300 text-gray-900;
}

.item-list {
  @apply divide-y divide-yellow-500 md:divide-gray-300;
}
.item-list a {
  @apply cursor-default;
}
a.active li {
  @apply bg-blue-400;
}

.item-list li .title {
  @apply text-sm font-medium text-gray-900;
}

.item-list a.active li .title {
  @apply text-white;
}

.item-list li .subtitle {
  @apply text-xs font-light text-gray-500;
}

.item-list a.active li .subtitle {
  @apply text-gray-100;
}

.highlight {
  @apply text-gray-900;
}

.header-button {
  @apply inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white;
}

.header-button-destroy {
  @apply bg-red-600 text-white;
}

.header-button-destroy:hover {
  @apply bg-red-700;
}

ul.timeline li:last-child span.line {
  display: none;
}


/* List of items (such as contacts, comapines.) */
aside.sidebar {
  @apply w-96 relative order-first flex flex-col flex-shrink-0 border-r border-gray-200;
}

main.details {
  @apply flex-1 relative z-0 overflow-y-auto bg-gray-200 pt-7;
}

.ember-basic-dropdown-trigger {
  @apply w-full p-2 duration-300 transition rounded border border-blue-200 focus:outline-none focus:ring focus:border-blue-300;
}

.ember-power-select-option {
  @apply p-2;
}

#navigation-bar {
  @apply h-full absolute md:static z-40 flex overflow-hidden bg-white;
}

#mobile-nav-backdrop {
  @apply h-full w-full bg-black bg-opacity-50;
  /* Apply a blur effect. There is no tailwind class for this. */
  backdrop-filter: blur(4px);
}

/* 
Item List

The item list is the container for the list of contact/companies/etc.

When no element is selected e.g. on a route like

/main/people the item list gets has the addtional class "index-route".
On mobile devices (size class sm) the list is hidden when looking at an object.

Also on mobile devices when the list is visible, it takes over the entire screen.
*/

aside.item-list {
  @apply hidden w-96 md:flex flex-col flex-shrink-0 h-full border-r border-gray-300;  
}

/**
Style for the item list, when the user is on an index route.
*/
aside.item-list.index-route {
  @apply flex w-full md:w-auto;
}

/**
This selector matches the "main" element, when its next to an item list.
When no item is selected (.index-route) The main view is hidden so that on
mobile devices the item list can take the full screen.
*/
aside.item-list.index-route + main { 
  @apply hidden md:w-full;
}


#mobile-sidebar-wormhole {
  @apply absolute z-40;
}